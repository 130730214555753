import { EyeIcon } from 'lucide-react';
import { Link } from 'react-router-dom';

import { DataTableColumnHeader } from '@/components/data-table-column-header';
import { ShortenedUUID } from '@/components/shortened-uuid';
import type { Tid } from '@/hooks/use-tids-query';

import type { ColumnDef } from '@tanstack/react-table';

export const tidColumns: ColumnDef<Tid>[] = [
  {
    accessorKey: 'createdAt',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Activated at" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {row.getValue('createdAt')}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'id',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Tid" />
    ),
    cell: ({ row }) => {
      return <ShortenedUUID uuid={row.getValue('id')} />;
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: 'orderId',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Order Id" />
    ),
    cell: ({ row }) => {
      return <ShortenedUUID uuid={row.getValue('orderId')} />;
    },
  },
  {
    accessorKey: 'view',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="View Scans" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <Link to={`/admin/scans/${row.original.id}`}>
            <EyeIcon className="h-4 w-4" />
          </Link>
        </div>
      );
    },
  },
];
