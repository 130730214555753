import { useQuery } from '@tanstack/react-query';
import { usePasswordless } from 'amazon-cognito-passwordless-auth/react';

import { apiPaths } from '@/constants/api-paths';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import { BrandSchema } from '@/schemas/brand';

import { useAppSelector } from './use-app-selector';

const BrandApiResponseSchema = createApiResponseSchema(BrandSchema);

async function getBrand(brandId: string) {
  const response = await api.get(apiPaths.BRAND_BY_ID({ brandId }));
  const { data } = BrandApiResponseSchema.parse(response.data);
  return { brand: data };
}

export function useBrand() {
  const brandId = useAppSelector((state) => state.brands.selected?.id);

  return useQuery({
    queryKey: ['brand', brandId],
    queryFn: () => getBrand(brandId ?? ''),
    enabled: Boolean(brandId),
  });
}
