import { usePasswordless } from 'amazon-cognito-passwordless-auth/react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import avatarImage from '@/assets/avatar-image.png';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Authorization } from '@/lib/authorization';

export function UserNav() {
  const {
    signOut,
    tokensParsed,
    signInStatus,
    showAuthenticatorManager,
    toggleShowAuthenticatorManager,
  } = usePasswordless();
  const navigate = useNavigate();
  const [showStepUpAuth, setShowStepUpAuth] = useState(false);

  if (showStepUpAuth && signInStatus !== 'SIGNED_IN') setShowStepUpAuth(false);

  const email = tokensParsed?.idToken.email;

  const signoutHandler = () => {
    signOut();
    navigate('/');
  };
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button className="relative h-8 w-8 rounded-full" variant="ghost">
          <Avatar className="h-8 w-8">
            <AvatarImage alt="avatar" src={avatarImage} />
            <AvatarFallback>JH</AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-56" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            {/* <p className="text-sm font-medium leading-none">João Hortale</p> */}
            <p className="text-xs leading-none text-muted-foreground">
              {email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          {/* <DropdownMenuItem>
            Profile
            <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
          </DropdownMenuItem> */}
          <Authorization allowedRoles={['Admin']}>
            <DropdownMenuItem
              onClick={() => {
                navigate('settings/brand');
              }}
            >
              Settings
              <DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
            </DropdownMenuItem>
          </Authorization>
          <DropdownMenuItem
            disabled={showAuthenticatorManager}
            onClick={() => {
              toggleShowAuthenticatorManager();
            }}
          >
            Manage authenticators
            <DropdownMenuShortcut>⌘M</DropdownMenuShortcut>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <a
              href="https://billing.forgestop.com/"
              rel="noopener"
              target="_blank"
            >
              Billing
            </a>
            <DropdownMenuShortcut>⌘B</DropdownMenuShortcut>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Link to="support">Support</Link>
            <DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={signoutHandler}>
          Log out
          <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
