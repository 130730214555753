import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { toast } from '@/components/ui/use-toast';
import { apiPaths } from '@/constants/api-paths';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import { BrandSchema, type Brand } from '@/schemas/brand';
import { selectBrand } from '@/store/brands/brands.slice';

import { useAppDispatch } from './use-app-dispatch';
import { useAppSelector } from './use-app-selector';

const BrandApiResponseSchema = createApiResponseSchema(BrandSchema);

interface UpdateBrandLogoParams {
  brandId: string;
  formData: FormData;
}

async function updateBrandLogo({ brandId, formData }: UpdateBrandLogoParams) {
  const response = await api.patch(
    apiPaths.BRAND_LOGO_BY_ID({ brandId }),
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    },
  );

  return BrandApiResponseSchema.parse(response.data);
}

export function useUpdateBrandLogoMutation() {
  const queryClient = useQueryClient();
  const selectedBrand = useAppSelector((state) => state.brands.selected);
  const dispatch = useAppDispatch();

  const selectBrandHandler = useCallback(
    (brand: Brand) => {
      dispatch(selectBrand(brand));
    },
    [dispatch],
  );
  return useMutation({
    mutationFn: (formData: FormData) =>
      updateBrandLogo({
        brandId: selectedBrand?.id ?? '',
        formData,
      }),
    mutationKey: ['update-brand-logo', selectedBrand?.id],
    onSuccess: (response) => {
      selectBrandHandler(response.data);
      toast({
        title: 'Brand logo updated',
      });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['companies'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['brand', selectedBrand?.id],
      });
    },
  });
}
