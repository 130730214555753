import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import { toast } from '@/components/ui/use-toast';
import { apiPaths } from '@/constants/api-paths';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import type { Batch } from '@/schemas/batch';

import { useAppSelector } from './use-app-selector';

interface UpdateBatchParams {
  brandId: string;
  productId: string;
  batchId: string;
}

export const updateBatchSchema = z.object({
  tags: z.array(z.string()).optional().nullable(),
});

export type UpdateBatchFormData = z.infer<typeof updateBatchSchema>;

const BatcApiResponseSchema = createApiResponseSchema(updateBatchSchema);

async function updateBatch({
  brandId,
  productId,
  batchId,
  ...data
}: UpdateBatchParams) {
  const response = await api.put<Batch>(
    apiPaths.BATCH_BY_ID_NESTED({ brandId, productId, batchId }),
    data,
  );

  const batch = BatcApiResponseSchema.parse(response.data);

  return { batch };
}

export function useUpdateBatchMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateBatch,
    mutationKey: ['update-batch'],
    onSuccess: () => {
      toast({
        title: 'Batch updated successfully',
      });
    },
    onSettled: async (_, __, { brandId, productId, batchId }) => {
      await queryClient.invalidateQueries({
        queryKey: ['batches'],
      });

      await queryClient.invalidateQueries({
        queryKey: ['batch', brandId, productId, batchId],
      });
    },
    onError: (error) => {
      toast({
        title: 'Error creating batch',
        description: error.message,
        variant: 'destructive',
      });
    },
  });
}
