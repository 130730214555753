import { useCallback } from 'react';

import type { BrandFormData } from '@/components/forms/brand-form';
import { BrandForm } from '@/components/forms/brand-form';
import { FileUploadForm } from '@/components/forms/image-upload-form';
import { Separator } from '@/components/ui/separator';
import { useBrand } from '@/hooks/use-brand';
import { useUpdateBrandLogoMutation } from '@/hooks/use-update-brand-logo-mutation';
import { useUpdateBrandMutation } from '@/hooks/use-update-brand-mutation';

export function SettingsBrand() {
  const { data } = useBrand();
  const updateBrandLogoMutation = useUpdateBrandLogoMutation();
  const updateBrandMutation = useUpdateBrandMutation();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const formData = new FormData();

      formData.append('logo', acceptedFiles[0]);

      updateBrandLogoMutation.mutate(formData);
    },
    [updateBrandLogoMutation],
  );

  const onSubmit = (brand: BrandFormData) => {
    updateBrandMutation.mutate(brand);
  };

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Brand</h3>
        <p className="text-sm text-muted-foreground">
          Fill out your brand information.
        </p>
      </div>
      <Separator />
      <FileUploadForm
        filename={data?.brand.logo ?? undefined}
        label="Logo"
        onDrop={onDrop}
        url={data?.brand.logoUrl ?? undefined}
      />
      <BrandForm data={data?.brand} onSubmit={onSubmit} />
    </div>
  );
}
