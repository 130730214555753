import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { apiPaths } from '@/constants/api-paths';
import { formatDate } from '@/helpers/format-date';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';

export const ChipModelSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  type: z.literal('nfc_chip'),
  description: z.string(),
  manufacturer: z.string(),
  specs: z.string(),
  isActive: z.boolean(),
  createdAt: z.string().transform(formatDate),
  updatedAt: z.string().transform(formatDate),
  alias: z.string(),
  encodingPlaceholder: z.string().nullable(),
});

export type ChipModel = z.infer<typeof ChipModelSchema>;

const ChipModelApiResponseSchema = createApiResponseSchema(
  z.array(ChipModelSchema),
);

async function getCatalogItems() {
  const response = await api.get(apiPaths.CATALOG);
  const { data } = ChipModelApiResponseSchema.parse(response.data);
  return { catalog: data };
}

export function useCatalogItems() {
  return useQuery({
    queryKey: ['catalog-items'],
    queryFn: getCatalogItems,
  });
}
