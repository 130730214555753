import { Outlet } from 'react-router-dom';

import { SidebarNav } from '@/components/sidebar-nav';
import { Separator } from '@/components/ui/separator';

const sidebarNavItems = [
  {
    title: 'Profile',
    href: 'profile',
    disabled: true,
  },
  {
    title: 'Brand',
    href: 'brand',
    disabled: false,
  },
  {
    title: 'Account',
    href: 'account',
    disabled: true,
  },
  {
    title: 'Appearance',
    href: 'appearance',
    disabled: true,
  },
  {
    title: 'Notifications',
    href: 'notifications',
    disabled: true,
  },
  {
    title: 'Display',
    href: 'display',
    disabled: true,
  },
];

export function SettingsLayout() {
  return (
    <>
      <div className="md:hidden">
        <img
          alt="Forms"
          className="block dark:hidden"
          height={791}
          src="/examples/forms-light.png"
          width={1280}
        />
        <img
          alt="Forms"
          className="hidden dark:block"
          height={791}
          src="/examples/forms-dark.png"
          width={1280}
        />
      </div>
      <div className="hidden space-y-6 p-10 pb-16 md:block">
        <div className="space-y-0.5">
          <h2 className="text-2xl font-bold tracking-tight">Settings</h2>
          <p className="text-muted-foreground">
            Manage your account settings and set e-mail preferences.
          </p>
        </div>
        <Separator className="my-6" />
        <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
          <aside className="-mx-4 lg:w-1/5">
            <SidebarNav items={sidebarNavItems} />
          </aside>
          <div className="flex-1 lg:max-w-2xl">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
