import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { BrandFormData } from '@/components/forms/brand-form';
import { toast } from '@/components/ui/use-toast';
import { apiPaths } from '@/constants/api-paths';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import type { Brand } from '@/schemas/brand';
import { BrandSchema } from '@/schemas/brand';

import { useAppSelector } from './use-app-selector';

const BrandApiResponseSchema = createApiResponseSchema(BrandSchema);

async function updateBrand({ id, ...brand }: Required<BrandFormData>) {
  const { data } = await api.put<Brand>(apiPaths.BRAND_BY_ID({ brandId: id }), {
    ...brand,
  });

  return BrandApiResponseSchema.parse(data);
}

export function useUpdateBrandMutation() {
  const selectedBrand = useAppSelector((state) => state.brands.selected);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (brand: BrandFormData) =>
      updateBrand({ id: selectedBrand?.id ?? '', ...brand }),
    mutationKey: ['update-brand'],
    onSuccess: () => {
      toast({
        title: 'Brand updated',
      });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['brands'],
      });
    },
  });
}
