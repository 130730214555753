import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import type { Batch } from '@/schemas/batch-nested';
import type { Product } from '@/schemas/product';

export const createTagFormSchema = z.object({
  orderId: z.string(),
  uid: z
    .string({ required_error: 'UID is required' })
    // .regex(/^([0-9a-fA-F]+)$/, {
    //   message: 'Invalid hexadecimal string',
    // })
    .min(14, {
      message: 'UID must be at least 14 characters.',
    })
    .max(16, {
      message: 'UID must not be longer than 16 characters.',
    }),
  roll: z.string({ required_error: 'Roll is required' }),
  sampleInfo: z
    .object({
      purpose: z.string().optional(),
      destination: z.string().optional(),
      testingCriteria: z.string().optional(),
    })
    .optional(),
});

export type CreateTagFormData = z.infer<typeof createTagFormSchema>;

export function TagForm({
  orderId,
  onCancel,
  onSubmit,
}: {
  orderId: string;
  onCancel: () => void;
  onSubmit: (product: CreateTagFormData) => void;
  batches?: Batch[];
  products?: Product[];
}) {
  const submitHandler = (tag: CreateTagFormData) => {
    onSubmit(tag);
  };

  const form = useForm<CreateTagFormData>({
    defaultValues: {
      orderId,
      uid: '',
      roll: '',
      sampleInfo: {
        destination: '',
        purpose: '',
        testingCriteria: '',
      },
    },
    resolver: zodResolver(createTagFormSchema),
  });

  return (
    <Form {...form}>
      <form className="space-y-8" onSubmit={form.handleSubmit(submitHandler)}>
        <FormField
          control={form.control}
          name="uid"
          render={({ field }) => (
            <FormItem>
              <FormLabel>UID</FormLabel>
              <FormControl>
                <Input maxLength={16} placeholder="66F113EE8B9521" {...field} />
              </FormControl>
              <FormDescription>This is the Tag identifier.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="roll"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Roll</FormLabel>
              <FormControl>
                <Input maxLength={128} placeholder="ROLL-123" {...field} />
              </FormControl>
              <FormDescription>This is the roll identifier</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="sampleInfo.purpose"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Purpose</FormLabel>
              <FormControl>
                <Input placeholder="Quality control" {...field} />
              </FormControl>
              <FormDescription>This is the Tag identifier.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="sampleInfo.destination"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Destination</FormLabel>
              <FormControl>
                <Input placeholder="R&D Department" {...field} />
              </FormControl>
              <FormDescription>This is the Tag identifier.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="sampleInfo.testingCriteria"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Testing Criteria</FormLabel>
              <FormControl>
                <Input placeholder="Durability Test" {...field} />
              </FormControl>
              <FormDescription>This is the Tag identifier.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2">
          <Button onClick={onCancel} variant="outline">
            Cancel
          </Button>
          <Button type="submit">Continue</Button>
        </div>
      </form>
    </Form>
  );
}
