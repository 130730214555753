import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import { toast } from '@/components/ui/use-toast';
import { apiPaths } from '@/constants/api-paths';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import type { Product } from '@/schemas/product';
import { ProductSchema } from '@/schemas/product';

import { useAppSelector } from './use-app-selector';

const CatalogItemSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  type: z.string(),
  description: z.string(),
  manufacturer: z.string(),
  specs: z.string(),
  isActive: z.boolean(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  alias: z.string(),
  encodingPlaceholder: z.string().nullable(),
});

const OrderCatalogItemSchema = z.object({
  orderId: z.string().uuid(),
  catalogItemId: z.string().uuid(),
  amount: z.number(),
  catalogItem: CatalogItemSchema,
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export const OrderResponseSchema = z.object({
  id: z.string().uuid(),
  status: z.enum(['Pending']), // Add more statuses as needed
  code: z.string(),
  brandId: z.string().uuid(),
  providerId: z.string().nullable(),
  requiredAt: z.string().datetime(),
  catalogItems: z.array(OrderCatalogItemSchema),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export type OrderResponse = z.infer<typeof OrderResponseSchema>;

const OrderApiResponseSchema = createApiResponseSchema(OrderResponseSchema);

export interface CatalogItem {
  catalogItemId: string;
  amount: number;
}

export interface CreateOrderData {
  code: string;
  catalogItems: CatalogItem[];
  requiredAt?: Date;
}

async function createOrder({
  brandId,
  ...order
}: CreateOrderData & { brandId: string }) {
  const { data } = await api.post<Product>(apiPaths.ORDERS({ brandId }), {
    ...order,
  });

  return OrderApiResponseSchema.parse(data);
}

export function useCreateOrderMutation() {
  const selectedBrand = useAppSelector((state) => state.brands.selected);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (order: CreateOrderData) =>
      createOrder({ ...order, brandId: selectedBrand?.id ?? '' }),
    mutationKey: ['create-order'],
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['orders', selectedBrand?.id],
      });
    },
  });
}
