import { usePasswordless } from 'amazon-cognito-passwordless-auth/react';
import { Copy } from 'lucide-react';
import { useCallback, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { FileUploadForm } from '@/components/forms/file-upload-form';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { toast } from '@/components/ui/use-toast';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useBulkUploadMutation } from '@/hooks/use-bulk-upload-mutation';
import { useCompany } from '@/hooks/use-company';
import { useCreateTagMutation } from '@/hooks/use-create-tag-mutation';

import { CreateOrderForm } from './components/forms/create-order-form';
import { TagForm } from './components/forms/create-tag-form';
import { OrderForm } from './components/forms/order-form';

import type { CreateTagFormData } from './components/forms/create-tag-form';

export type SelectedDialogOrder =
  | 'create-order'
  | 'upload-tags'
  | 'update-order'
  | 'create-tag'
  | 'encoding-url';

interface DialogProps {
  header: string;
  description: string;
  component: JSX.Element | null;
}

type DialogDictionary = Record<SelectedDialogOrder, DialogProps>;

export interface OrderLayoutOutletContext {
  openDialogHandler: (params: DialogHandlerParams) => void;
}

type DialogHandlerParams =
  | {
      dialog: Extract<SelectedDialogOrder, 'create-order'>;
    }
  | {
      dialog: Extract<SelectedDialogOrder, 'upload-tags'>;
      orderId: string;
    }
  | {
      dialog: Extract<SelectedDialogOrder, 'update-order'>;
      orderId: string;
    }
  | {
      dialog: Extract<SelectedDialogOrder, 'create-tag'>;
      orderId: string;
    }
  | {
      dialog: Extract<SelectedDialogOrder, 'encoding-url'>;
      orderId: string;
      alias: string;
      encodingPlaceholder: string | null;
      domain: string;
    };

export function OrdersLayout() {
  const [file, setFile] = useState<File | null>(null);
  const bulkUploadMutation = useBulkUploadMutation();
  const [selectedDialog, setSelectedDialog] = useState<
    SelectedDialogOrder | undefined
  >();
  const [selectedOrderId, setSelectedOrderId] = useState<string>();
  const [encodingUrl, setEncodingUrl] = useState<string>();
  const { data } = useCompany();
  const { tokensParsed } = usePasswordless();
  const selectedBrand = useAppSelector((state) => state.brands.selected);
  const createTagMutation = useCreateTagMutation();
  const brandId = selectedBrand?.id;
  const crmCompanyID = data?.company.companyCrmId;
  const email = tokensParsed?.idToken.email;

  const openDialogHandler = (params: DialogHandlerParams) => {
    if (params.dialog !== 'create-order') {
      setSelectedOrderId(params.orderId);
    }
    if (params.dialog === 'encoding-url') {
      setEncodingUrl(
        `${params.domain}/${params.alias}/${params.orderId}?${params.encodingPlaceholder}`,
      );
    }
    setSelectedDialog(params.dialog);
  };

  const closeDialogHandler = () => {
    setSelectedDialog(undefined);
    setSelectedOrderId(undefined);
  };

  const createOrderHandler = () => {
    closeDialogHandler();
  };

  const updateOrderHandler = () => {
    toast({ title: 'Order created' });
    // console.log('Order updated');
    closeDialogHandler();
  };

  const uploadTagsHandler = () => {
    if (!file) {
      toast({
        title: 'Error',
        description: 'Please select a file to upload',
        variant: 'destructive',
      });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    bulkUploadMutation.mutate(
      {
        orderId: selectedOrderId ?? '',
        formData,
      },
      {
        onSuccess: () => {
          toast({
            title: 'Success',
            description: 'Tags were successfully uploaded',
            variant: 'default',
          });
          setFile(null); // Reset file state
        },
        onError: (error) => {
          toast({
            title: 'Upload failed',
            description:
              error instanceof Error ? error.message : 'Failed to upload tags',
            variant: 'destructive',
          });
        },
        onSettled: () => {
          closeDialogHandler();
        },
      },
    );
  };

  const createTagHandler = useCallback(
    (tag: CreateTagFormData) => {
      createTagMutation.mutate(tag);
      setSelectedDialog(undefined);
    },
    [createTagMutation],
  );

  const dialogDictionary: DialogDictionary = {
    'create-order': {
      header: 'Create new order',
      description: 'Please fill out the required fields to create an order',
      component:
        brandId && crmCompanyID && email ? (
          <CreateOrderForm
            defaultValues={{
              Email: email,
              SingleLine: brandId,
              SingleLine2: crmCompanyID,
            }}
            onSubmit={createOrderHandler}
          />
        ) : null,
    },
    'update-order': {
      header: 'Update order',
      description: 'Please fill out the required fields to update the order',
      component: (
        <OrderForm
          onCancel={closeDialogHandler}
          onSubmit={updateOrderHandler}
        />
      ),
    },
    'upload-tags': {
      header: 'Upload tags',
      description: 'Please select a file to be uploaded',
      component: (
        <FileUploadForm
          file={file}
          handleUpload={uploadTagsHandler}
          isSubmitting={bulkUploadMutation.isPending}
          setFile={setFile}
        />
      ),
    },
    'create-tag': {
      header: 'Create Tag',
      description: `Add a new tag`,
      component: (
        <TagForm
          onCancel={closeDialogHandler}
          onSubmit={createTagHandler}
          orderId={selectedOrderId || ''}
        />
      ),
    },
    'encoding-url': {
      header: 'Generated encoding URL',
      description: 'Please copy the generated URL to encode your chip',
      component: (
        <div className="flex gap-2">
          <Input disabled value={encodingUrl} />
          <Button
            onClick={() => {
              void navigator.clipboard.writeText(encodingUrl || '');
              toast({
                title: 'Copied to clipboard',
                duration: 2000,
              });
            }}
            size="icon"
            variant="outline"
          >
            <Copy className="h-4 w-4" />
          </Button>
        </div>
      ),
    },
  };

  return (
    <>
      <div className="flex-1 space-y-4 p-8 pt-6">
        <div className="flex items-center justify-between space-y-2">
          <div>
            <h2 className="text-3xl font-bold tracking-tight">Orders</h2>
          </div>
          <div className="flex items-center space-x-2">
            <Button
              onClick={() => {
                openDialogHandler({ dialog: 'create-order' });
              }}
            >
              Create new order
            </Button>
          </div>
        </div>
        <Outlet
          context={
            {
              openDialogHandler,
            } as OrderLayoutOutletContext
          }
        />
      </div>
      <Dialog onOpenChange={closeDialogHandler} open={Boolean(selectedDialog)}>
        <DialogContent className="flex-1">
          <DialogHeader>
            <DialogTitle>
              {selectedDialog ? dialogDictionary[selectedDialog].header : null}
            </DialogTitle>
            <DialogDescription>
              {selectedDialog
                ? dialogDictionary[selectedDialog].description
                : null}
            </DialogDescription>
          </DialogHeader>
          {selectedDialog ? dialogDictionary[selectedDialog].component : null}
        </DialogContent>
      </Dialog>
    </>
  );
}
