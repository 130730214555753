import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { BrandFormData } from '@/components/forms/brand-form';
import { apiPaths } from '@/constants/api-paths';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import type { Brand } from '@/schemas/brand';
import { BrandSchema } from '@/schemas/brand';

import { toast } from './use-toast';

const BrandApiResponseSchema = createApiResponseSchema(BrandSchema);

async function createBrand(brand: BrandFormData) {
  const { data } = await api.post<Brand>(apiPaths.BRANDS, {
    ...brand,
  });

  return BrandApiResponseSchema.parse(data);
}

export function useCreateBrandMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (brand: BrandFormData) => createBrand(brand),
    mutationKey: ['create-brand'],
    onSuccess: () => {
      toast({
        title: 'Brand created',
      });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['brands'],
      });
    },
  });
}
